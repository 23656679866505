import { ReactComponent as UserIcon } from "assets/icon/user.svg"
import { Button } from "components"
import { MaterialIcon } from "components/common/MaterialIcon"
import Typography from "components/common/Typography"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"
import { PreviewBenefit } from "constants/enums/preview-benefit"
import { compose, withHooks, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import { useLocation } from "react-router-dom"
import paths from "routes/paths"
import styled from "styled-components"
import Theme from "theme/custom"

const Container = styled("div")`
  width: 100%;
  height: 230px;
  .content {
    padding: 16px;
  }
`

const InsuranceCard = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: ${Theme.colors["Primary/Background"]};
  border-radius: 16px;
  padding: 16px;
  .inner-container {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    max-width: 80%;
  }
`

interface FooterProps {
  insurancesList: string
  handleClickNext: () => void
  t: TFunction
}

const FooterComponent = (props: FooterProps) => (
  <Container>
    <div className="content">
      <Typography variant="h3">{props.t(".title")}</Typography>
      <InsuranceCard>
        <UserIcon style={{ padding: "3px" }} fontSize={24} fill={Theme.colors["Primary/Primary Text"]} />
        <div className="inner-container">
          <Typography variant="h3">{props.t(".ownInsurance")}</Typography>
          <Typography variant="body1" color={Theme.colors["Text/Placeholder"]}>
            {props.insurancesList}
          </Typography>
        </div>
      </InsuranceCard>
      <Button onClick={props.handleClickNext} style={{ marginBottom: "56px" }}>
        {props.t(".next")}
        <MaterialIcon fontSize="inherit" name="ChevronRight" />
      </Button>
    </div>
  </Container>
)

const enhancer = compose(
  withTranslation({ prefix: "pages.main.benefit.Footer" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useCallback, useParams } = hooks
    const { insurances, type } = props
    const { id } = useParams()

    const insurancesList = useMemo(() => insurances.join(","), [insurances])

    const { pathname } = useLocation()
    const currentPath = useMemo(() => {
      return pathname.split("/")[1]
    }, [pathname])

    const handleClickNext = useCallback(() => {
      if (type === EnumMasterBenefitInsuranceType.custom) {
        currentPath === PreviewBenefit.preview
          ? paths.previewBenefitCustomPath(id).push()
          : paths.benefitCustomManagementPath(id).push()
      } else if (type === EnumMasterBenefitInsuranceType.package) {
        currentPath === PreviewBenefit.preview
          ? paths.previewBenefitPackagePath(id).push()
          : paths.benefitPackageManagementPath(id).push()
      }
    }, [id, type, currentPath])

    return { insurancesList, handleClickNext }
  }),
)

export const BenefitFooter = enhancer(FooterComponent)
